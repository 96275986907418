import React from 'react'
import Faq from '../Components/Faq.jsx'
import Footer from '../Components/Footer.jsx'

function FaqPage() {
  return (
    <div className='faq-page-container'>
      <Faq/>
      <Footer/>
    </div>
  )
}

export default FaqPage