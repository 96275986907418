import React from 'react'
import { Link } from 'react-router-dom'
import '../Styles/fullstackCallToActionl.css'

function FullstackCallToAction() {
  return (
    <div className='fullstack-call-to-action-container'>
        <h2>Fullstack Web Development</h2>
        <p>Ready to dive into the thrilling realm of full-stack development? Our all-encompassing Full-Stack Development Program opens the door to your future.</p>
        <div className="fullstack-buttons">
                <button className='enrol'> 
                    <Link to ='/FullstackApplyPage'> Join Us Now</Link>
                </button>
                {/* <button className='syllabus'> 
                    <Link to ='/FullstackSyllabusPage'> Download Syllabus</Link>
                </button> */}
            </div>
    </div>
  )
}

export default FullstackCallToAction