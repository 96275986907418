import React from 'react'
import '../Styles/callToAction.css'
import { Link } from 'react-router-dom'




function CallToAction() {
  return (
    <div className='call-to-action-container'>
        <h3>Ready to Elevate Your Career?</h3>
        <p>If you’re passionate about building or advancing a career in tech, Pearlcity Hub has you covered. We specialize in equipping you with industry-relevant skills that prepare you for the future of work and connect you with exciting job opportunities locally and globally.</p>

        <button>
           <Link to='/Browse'>Browse Course</Link>
        </button>
    </div>
  )
}

export default CallToAction