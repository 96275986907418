import React from 'react'
import '../Styles/footer.css'
import Logo from '../Assets/pearlcity-logo-2.png'
import X from '../Assets/X-logo.svg'
import Facebook from '../Assets/facebook.svg'
import Instagram from '../Assets/instagram.svg'
import LinkedIn from '../Assets/linkedin.svg'
import { Link } from 'react-router-dom'

function Footer() {
  const getYear = new Date().getFullYear()
  return (
    <div className='footer-container'>
        <div className="footer-logo">      
            <Link to='/'>
             <img src={Logo} alt="baansure-logo" /></Link>
        </div>   
        <div className="useful-links">    
          <Link to='/'>Home</Link>
          <Link to='/student'>Individual</Link>
          <Link to='/business'>Business</Link>
          <Link to='/About'>About Us</Link>
          <Link to='/FaqPage'>FAQ</Link>
          <Link to='/Contact'>Contact</Link>         
        </div>   
        <div className="social-icon">
          <a href="https://x.com/PearlcityH20366"target='_blank' rel='noreferrer'><img src={X} alt="X-logo" /></a>
          <a href="https://www.facebook.com/profile.php?id=61570301254562"target='_blank' rel='noreferrer'> <img className='facebook' src={Facebook} alt="facebook-logo" /></a>
          <a href="https://www.instagram.com/pearlcity_hub"target='_blank' rel='noreferrer'><img src={Instagram} alt="instagram-logo" /></a>            
          <a href="https://www.linkedin.com/company/105754383/admin/dashboard/"target='_blank' rel='noreferrer'><img src={LinkedIn} alt="linkedin-logo" /></a>            
        </div>    

        <div className="copy-right">
          <div className="right-text">
            <p>Copyright &copy; {getYear} Pearlcity-Hub</p>     
            <p>Powered by: pearlcity-hub.com</p>
          </div>
          <div className="policy-terms">
             <Link to='/PrivacyPolicyPage'>Privacy Policy</Link>
             <Link to='/TermsPage'>Terms of Use</Link>

          </div>
          

          
        </div>            
    

           
     
    </div>
        

  )
}

export default Footer