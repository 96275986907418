import React from 'react'
import { Link } from 'react-router-dom'
import '../Styles/backendCallToActionl.css'

function BackendCallToAction() {
  return (
    <div className='backend-call-to-action-container'>
        <h2>Backend With Python Django</h2>
        <p>Develop and launch powerful web applications and mobile apps with Django. Leverage Django's advanced security features to build secure and reliable solutions.</p>
        <div className="backend-buttons">
                <button className='enrol'> 
                    <Link to ='/BackendApplyPage'> Join Us Now</Link>
                </button>
                {/* <button className='syllabus'> 
                    <Link to ='/BackendSyllabusPage'> Download Syllabus</Link>
                </button> */}
            </div>
    </div>
  )
}

export default BackendCallToAction