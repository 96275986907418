import React from 'react'
import { Link } from 'react-router-dom'
import BackendImage from '../Assets/backend.jpg'
import DurationIcon from '../Assets/duration.svg'
import DateIcon from '../Assets/date-1.svg'
import LevelIcon from '../Assets/level.svg'
import RequiredIcon from '../Assets/required.svg'
import ProjectIcon from '../Assets/project.svg'
import CostIcon from '../Assets/bill.svg'
import ScheduleIcon from '../Assets/schedule.svg'
import LearnIcon from '../Assets/learn.svg'
import AboutIcon from '../Assets/about.svg'
import CourseIon from '../Assets/course.svg'
import '../Styles/backendPage.css'
import BackendCallToAction from '../Components/BackendCallToAction.jsx'
import Expectation from '../Components/Expectation.jsx'
import Faq from '../Components/Faq.jsx'
import Footer from '../Components/Footer.jsx'


function FrontEndPage() {

  return (
    <div className='backend-page-container'>
        <div className="backend-image">
            <img src={BackendImage} alt="backend-image" />
        </div>
        <div className="backend-text">
            <h2>Backend With  <span>Python Django</span></h2>
            <p>Develop and launch powerful web applications and mobile apps with Django. Leverage Django's advanced security features to build secure and reliable solutions.</p>
            <div className="backend-buttons">
                <button className='enrol'> 
                    <Link to ='/BackendApplyPage'> Join Us Now</Link>
                </button>
                {/* <button className='syllabus'> 
                    <Link to ='/BackendSyllabusPage'> Download Syllabus</Link>
                </button> */}
            </div>
            <div className="backend-status">
                <h5>Application Ongoing...</h5>
            </div>
        </div>
        <div className="backend-details">
            <div className="timeframe">
                <img src= {DurationIcon}alt="duration-icon" />
                <p>Timeframe</p>
                <h3>3 Months</h3>
                <p>Twice a Week</p>
            </div>
            <div className="date">
                <img src={DateIcon} alt="date-icon" />
                <p>Start Date</p>
                <h3>13Th January, 2025</h3>
            </div>
            <div className="level">
                <img src={LevelIcon} alt="level-icon"/>
                <p>Level</p>
                <h3>Beginner</h3>
            </div>
            <div className="required">
                <img src={RequiredIcon} alt="required-icon" />
                <p>Pre-requisites</p>
                <h3>A Laptop</h3>
            </div>
            <div className="project">
                <img src={ProjectIcon} alt="project-icon" />
                <p>Projects</p>
                <h3>1 Project</h3>
            </div>
            <div className="cost">
                <img src={CostIcon} alt="cost-bill-icon" />
                <p>Cost</p>
                <h3>N350,000 (Flexible Payment)</h3>
            </div>


        </div>
        <div className="what-to-know">
            <div className="about-course">
                <div className="about-heading">
                    <img src={AboutIcon} alt="about-icon" />
                    <h2>About the Course</h2>
                </div>
                <div className="about-text">
                    <p>Create and deploy powerful web applications and apps with Django, leveraging its security features to build safe and secure web solutions.</p>
                </div>

            </div>
            <div className="what-to-learn">
                <div className="what-to-learn-heading">
                    <img src={LearnIcon} alt="learning-icon" />
                    <h2>What you will Learn</h2>
                </div>
                <div className="what-to-learn-text">
                    <p>In this course, you will learn how to build a complete web application using Django Python framework.
                    Whether you are a beginner or an experienced website designer you will find the course very easy to learn and to start building your own web apps almost immediately.</p>
                    <p>By the end of the course, you will be able to build any category of the website with a backend, an e-commerce website, an admin dashboard, learn how to use Python in IoT applications and introduction to Data Science Use case.</p>
                    {/* <ul>
                        <li>Layout a simple webpage using HTML</li>
                        <li>Style a website element using CSS</li>
                        <li>Using Git and Github for version control</li>
                        <li>Create web projects with JavaScript</li>
                        <li>Query a database that uses and stores information</li>
                        <li>Design technical architecture using UML diagrams</li>
                        <li>Implement unit and integration tests</li>
                        <li>Web APIs and Asynchronous Applications</li>
                        <li>Optimize performance</li>
                        <li>Create responsive interfaces</li>
                        <li>Debug and troubleshoot errors and failures in JavaScript programs</li>
                        <li>Write and test software with JavaScript</li>
                    </ul> */}
                </div>

            </div>
            <div className="prerequisites">
                <div className="prerequisites-heading">
                    <img src={RequiredIcon} alt="required-icon" />
                    <h2>Pre-requisites</h2>
                </div>
                <div className="prerequisites-text">
                    <p>For effective learning experiences, the following are required:</p>
                    <ul>
                        <li> Access to a computer – Mac or Windows.</li>
                        <li> Little or no previous coding experience.</li>
                        <li>Laptop Specifications(8gb -16gb RAM, 256SSD or 500gb)</li>
                        <li>Willingness to learn.</li>
                    </ul>
                </div>

            </div>
            <div className="course-outline">
                <div className="course-outline-heading">
                    <img src={CourseIon} alt="course-icon" />
                    <h2>Course Outline</h2>
                </div>
                <div className="course-outline-text">
                    <p>The following are our course outline:</p>
                    <ul>
                        <li><span>Week 1: </span>Introduction to Python Programming</li>
                        <li><span>Week 2: </span>Data Structures & Looping Constructs</li>
                        <li><span>Week 3: </span>Functions & Methods</li>
                        <li><span>Week 4: </span>Object-Oriented Programming(OOP)</li>
                        <li><span>Week 5: </span>Building Apps with Django</li>
                        <li><span>Week 6: </span>Django Database Models class</li>
                        <li><span>Week 7: </span>Django and REST APIs</li>
                        <li><span>Week 8: </span> Front-End development tools</li>
                        <li><span>Week 9: </span>Django Templates</li>
                        <li><span>Week 10: </span>Deploying a Django Project</li>
                        <li><span>Week 11: </span>DevOps</li>
                        <li><span>Week 12: </span>Personal Project & Graduation</li>
                    </ul>
                   
                </div>

            </div>
            <div className="class-schedule">
                <div className="class-schedule-heading">
                    <img src={ScheduleIcon} alt="schedule-icon" />
                    <h2>Class Schedule</h2>
                </div>
                <div className="class-schedule-text">
                    <p><span>Days of Class: </span> Tuesdays and Thursdays</p>
                </div>
            </div>
        </div>
        <BackendCallToAction/>
        <Expectation/>
        <Faq/>
        <BackendCallToAction/>
        <Footer/>
       
       

    </div>
  )
}

export default FrontEndPage