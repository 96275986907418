import React from 'react'
import WhatWeDoCardData from '../Data/WhatWeDoCardData.js'
import DateIcon from '../Assets/date.svg'
import ScheduleIcon from '../Assets/schedule.svg'
import '../Styles/whatWeDo.css'
import { Link } from 'react-router-dom'

function WhatWeDo() {
  return (
    <div className='what-we-do-container'>
       <div className="what-we-do-text">
        <h2>Explore the Right Program for You</h2>
        <p>Our training adopts a hands-on learning style, tailored for newcomers and seasoned professionals alike.</p>
       </div>
       <div className="what-we-card-holder">
            { WhatWeDoCardData.map((items)=> (
                    <div key={items.id} className="what-we-do-cards">
                        <div className="card-image">
                            <img src={items.image} alt="tech-image" />
                        </div>
                        <div className="card-text">
                            <h3>{items.course}</h3>
                            <p>{items.desc}</p>
                            
                            <Link to={items.link}>
                            <button>Explore</button> 
                            </Link>

                            <div className="other-details">
                                <div className="duration">
                                    <img src={DateIcon} alt="date-icon" />
                                    <p>{items.duration}</p>
                                </div>
                                <div className="interval">
                                    <img src={ScheduleIcon} alt="schedule-icon" />
                                    <p>{items.schedule}</p>
                                
                                </div>
                            </div>

                        </div>
                    
                    </div>
            )) }
       </div>
       
    </div>
  )
}

export default WhatWeDo