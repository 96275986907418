import TeacherIcon from '../Assets/teachers.svg'
import HandOnIcon from '../Assets/hands-on.svg'
import CertIcon from '../Assets/certificate.svg'
import SupportIcon from '../Assets/support.svg'
import EnvironmentIcon from '../Assets/environment.svg'
import RefreshmentIcon from '../Assets/refreshment.svg'
import SouvenirIcon from '../Assets/gift.svg'

const ExpectationData = [
    {
        id: 1,
        icon: TeacherIcon,
        heading: 'Skilled and Knowledgeable Instructors',
        desc: 'Learn and receive guidance from top-notch instructors renowned in the industry.'
    },
    {
        id: 2,
        icon: HandOnIcon,
        heading: 'Practical and Interactive',
        desc: 'You’ll learn and receive mentorship from seasoned industry professionals.'
    },
    {
        id: 3,
        icon: CertIcon,
        heading: 'Certification',
        desc: 'Receive a physical certificate to validate your participation and showcase your achievement.'
    },
    {
        id: 4,
        icon: SupportIcon,
        heading: 'Reliable Support Network',
        desc: 'Join a thriving, supportive community of like-minded individuals.'
    },
    {
        id: 5,
        icon: EnvironmentIcon,
        heading: 'Ideal Learning Environment',
        desc: 'Our classrooms and surroundings provide a peaceful and supportive atmosphere for effective learning.'
    },
    {
        id: 6,
        icon: RefreshmentIcon,
        heading: 'Refreshments',
        desc: 'We offer light refreshments during every class.'
    },
    {
        id: 7,
        icon: SouvenirIcon,
        heading: 'Souvenirs',
        desc: 'You’ll receive a free t-shirt to help you feel right at home—because, why not?'
    },

]

export default ExpectationData;