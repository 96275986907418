import React from 'react'
import { Link } from 'react-router-dom'
import Frontend from '../Assets/frontend.jpg'
import DurationIcon from '../Assets/duration.svg'
import DateIcon from '../Assets/date-1.svg'
import LevelIcon from '../Assets/level.svg'
import RequiredIcon from '../Assets/required.svg'
import ProjectIcon from '../Assets/project.svg'
import CostIcon from '../Assets/bill.svg'
import ScheduleIcon from '../Assets/schedule.svg'
import LearnIcon from '../Assets/learn.svg'
import AboutIcon from '../Assets/about.svg'
import CourseIon from '../Assets/course.svg'
import '../Styles/frontendPage.css'
import FrontendCallToAction from '../Components/FrontendCallToAction.jsx'
import Expectation from '../Components/Expectation.jsx'
import Faq from '../Components/Faq.jsx'
import Footer from '../Components/Footer.jsx'


function FrontEndPage() {

  return (
    <div className='frontend-page-container'>
        <div className="frontend-image">
            <img src={Frontend} alt="frontend-image" />
        </div>
        <div className="frontend-text">
            <h2>Frontend Web Development <span>(with React Js)</span></h2>
            <p>Kickstart your journey as a Front-End Web Developer today. Master the skills to craft exceptional websites and interactive applications that deliver captivating user experiences.</p>
            <div className="frontend-buttons">
                <button className='enrol'> 
                    <Link to ='/FrontendApplyPage'> Join Us Now</Link>
                </button>
                {/* <button className='syllabus'> 
                    <Link to ='/FrontendSyllabusPage'> Download Syllabus</Link>
                </button> */}
            </div>
            <div className="frontend-status">
                <h5>Application Ongoing...</h5>
            </div>
        </div>
        <div className="frontend-details">
            <div className="timeframe">
                <img src= {DurationIcon}alt="duration-icon" />
                <p>Timeframe</p>
                <h3>3 Months</h3>
                <p>Twice a Week</p>
            </div>
            <div className="date">
                <img src={DateIcon} alt="date-icon" />
                <p>Start Date</p>
                <h3>13th January, 2025</h3>
            </div>
            <div className="level">
                <img src={LevelIcon} alt="level-icon"/>
                <p>Level</p>
                <h3>Beginner</h3>
            </div>
            <div className="required">
                <img src={RequiredIcon} alt="required-icon" />
                <p>Pre-requisites</p>
                <h3>A Laptop</h3>
            </div>
            <div className="project">
                <img src={ProjectIcon} alt="project-icon" />
                <p>Projects</p>
                <h3>1 Project</h3>
            </div>
            <div className="cost">
                <img src={CostIcon} alt="cost-bill-icon" />
                <p>Cost</p>
                <h3>N350,000 (Flexible Payment)</h3>
            </div>


        </div>
        <div className="what-to-know">
            <div className="about-course">
                <div className="about-heading">
                    <img src={AboutIcon} alt="about-icon" />
                    <h2>About the Course</h2>
                </div>
                <div className="about-text">
                    <p>Master the essential skills and tools required to create dynamic, interactive web experiences as a front-end developer. If you're looking to kickstart your journey in front-end web development, this course is perfect for you! You'll work on a variety of projects, starting from basic HTML pages and progressing to fully developed applications.</p>

                    <p>This course equips you with the knowledge and expertise to craft compelling, user-friendly web interfaces. It offers a step-by-step pathway to help you build a solid foundation and launch your career as a Frontend Engineer.</p>
                </div>

            </div>
            <div className="what-to-learn">
                <div className="what-to-learn-heading">
                    <img src={LearnIcon} alt="learning-icon" />
                    <h2>What you will Learn</h2>
                </div>
                <div className="what-to-learn-text">
                    <p>The Front-end Web Development Path provides a comprehensive roadmap, teaching you everything necessary to begin your journey as a Front-end Engineer from scratch. Along the way, you will learn:</p>
                    <ul>
                        <li>Layout a simple webpage using HTML</li>
                        <li>Style a website element using CSS</li>
                        <li>Using Git and Github for version control</li>
                        <li>Create web projects with JavaScript</li>
                        <li>Query a database that uses and stores information</li>
                        <li>Design technical architecture using UML diagrams</li>
                        <li>Implement unit and integration tests</li>
                        <li>Web APIs and Asynchronous Applications</li>
                        <li>Optimize performance</li>
                        <li>Create responsive interfaces</li>
                        <li>Debug and troubleshoot errors and failures in JavaScript programs</li>
                        <li>Write and test software with JavaScript</li>
                    </ul>
                </div>

            </div>
            <div className="prerequisites">
                <div className="prerequisites-heading">
                    <img src={RequiredIcon} alt="required-icon" />
                    <h2>Pre-requisites</h2>
                </div>
                <div className="prerequisites-text">
                    <p>For effective learning experiences, the following are required:</p>
                    <ul>
                        <li> Access to a computer – Mac or Windows.</li>
                        <li> Little or no previous coding experience.</li>
                        <li>Laptop Specifications(8gb -16gb RAM, 256SSD or 500gb)</li>
                        <li>Willingness to learn.</li>
                    </ul>
                </div>

            </div>
            <div className="course-outline">
                <div className="course-outline-heading">
                    <img src={CourseIon} alt="course-icon" />
                    <h2>Course Outline</h2>
                </div>
                <div className="course-outline-text">
                    <p>The following are our course outline:</p>
                    <ul>
                        <li><span>Week 1: </span>Introduction to Web Development/HTML</li>
                        <li><span>Week 2: </span>Styling HTML Web Pages</li>
                        <li><span>Week 3: </span>Intermediate CSS</li>
                        <li><span>Week 4: </span>Bootstrap and Responsive Design</li>
                        <li><span>Week 5: </span>JavaScript Fundamentals and DOM</li>
                        <li><span>Week 6: </span>JavaScript Fundamentals and DOM (II)</li>
                        <li><span>Week 7: </span>ES6 and JavaScript Modules</li>
                        <li><span>Week 8: </span>Project Week-Build a one-page website with Html, CSS, and Javascript</li>
                        <li><span>Week 9: </span>React – Getting Started</li>
                        <li><span>Week 10: </span>React – (ii)-Single Page Applications (SPAs) with React</li>
                        <li><span>Week 11: </span>React (iii)- Advanced Components</li>
                        <li><span>Week 12: </span>React (iv)- Using the Switch Tag</li>
                    </ul>
                    <h4>Finally - Capstone Project</h4>
                </div>

            </div>
            <div className="class-schedule">
                <div className="class-schedule-heading">
                    <img src={ScheduleIcon} alt="schedule-icon" />
                    <h2>Class Schedule</h2>
                </div>
                <div className="class-schedule-text">
                    <p><span>Days of Class:</span>Mondays and Wednesdays</p>
                </div>
            </div>
        </div>
        <FrontendCallToAction/>
        <Expectation/>
        <Faq/>
        <FrontendCallToAction/>
        <Footer/>
       
       

    </div>
  )
}

export default FrontEndPage