import React from 'react'
import { Link } from 'react-router-dom'
import '../Styles/graphicDesignCallToAction.css'

function GraphicDesignCallToAction() {
  return (
    <div className='graphic-design-call-to-action-container'>
        <h2>Graphic-design Web Development with React JS</h2>
        <p>Unlock your creative potential and learn how to bring ideas to life through visual design. This course is designed to equip students with the skills and knowledge needed to create compelling designs for print, digital media, and beyond.</p>
        <div className="graphic-design-buttons">
                <button className='enrol'> 
                    <Link to ='/GraphicDesignApplyPage'> Join Us Now</Link>
                </button>
                {/* <button className='syllabus'> 
                    <Link to ='/graphic-designSyllabusPage'> Download Syllabus</Link>
                </button> */}
            </div>
    </div>
  )
}

export default GraphicDesignCallToAction