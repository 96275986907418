const FaqData = [
    {
        id: 1,
        question: 'Are the programs on-site or online?',
        response: 'Our program is currently a 100% physical training program. However, our online training program is coming soon...'
    },
    {
        id: 2,
        question: 'What is the duration for a typical program?',
        response: 'A typical program at Pearlcity Hub runs for 3 Months, while our Fullstack program runs for 6 Months'
    },
    {
        id: 3,
        question: 'Where are you located?',
        response: '15 Bori Road, Rumuibekwe,Port Harcourt.'
    },
    {
        id: 4,
        question: 'Can I join a waiting list for an upcoming cohort?',
        response: 'Yes, you can. To join the waiting list, kindly apply for your desired course and get to let us know which cohort you intend to enroll for. We will get in touch with you.'
    },
    {
        id: 5,
        question: 'When will the next session take place?',
        response: 'You will see the dates for our next cohorts on your desired course of interest page. Feel free to reach out to us should you require clarifications. We will be happy to answer any questions and get in touch with you as soon as applications are open for another cohort.'
    },
    {
        id: 6,
        question: 'Do I need a laptop?',
        response: 'Yes, you will be requiring a laptop. All our classes are hands-on and very practical.'
    },
    
]

export default FaqData;