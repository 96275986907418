import React from 'react'
import { Link } from 'react-router-dom'
import '../Styles/landingPageStyle.css'
import WorldBk from '../Assets/world.jpeg'


function LandingPage() {
  return (
    <div className='landing-page-container'>
           
        <div className="landing-page-text">
            <h1>Empowering Minds Across the Globe, One Tech Skill at a Time</h1>
            <p>Discover a universe of possibilities with cutting-edge training designed to launch individuals and businesses into the future of technology.</p>
          <div className="landing-page-buttons">
            <Link className='student' to='/Student'>For Individuals</Link>
            <Link className='business' to='/Business'>For Businesses</Link>
          </div>           

        </div>
        <div className="landing-page-image">
          <img src={WorldBk} alt="world-background-image" />
        </div> 
    
    </div>
  )
}

export default LandingPage