import FullStack from '../Assets/fullstack.jpg'
import Frontend from '../Assets/frontend.jpg'
import Backend from '../Assets/backend.jpg'
import Ui from '../Assets/ui.jpg'
import GraphicDesign from '../Assets/graphic-design.jpg'

const  whatWeDoCardData = [
    {
        id: 1,
        image: Frontend,
        course: 'Frontend Web Development (with React Js)',
        desc: 'Kickstart your journey as a Front-End Web Developer today. Master the skills to craft exceptional websites and interactive applications that deliver captivating user experiences.',
        duration: '3 Months',
        schedule: 'Twice a week',
        link: '/FrontEndPage'
    },
    {
        id: 2,
        image: Backend,
        course: 'Backend With Python Django',
        desc: 'Develop and launch powerful web applications and mobile apps with Django. Leverage Django\'s advanced security features to build secure and reliable solutions.',
        duration: '3 Months',
        schedule: 'Twice a week',
        link: '/BackEndPage'

    },
    {
        id: 3,
        image: FullStack,
        course: 'Fullstack Web Development',
        desc: 'Ready to dive into the thrilling realm of full-stack development? Our all-encompassing Full-Stack Development Program opens the door to your future.',
        duration: '6 Months',
        schedule: 'Four times a week',
        link: '/FullStackPage'

    },
    {
        id: 4,
        image: Ui,
        course: 'UI/UX Design and Prototyping (Product Design)',
        desc: 'UI and UX design skills are highly sought after and versatile, spanning various products and industries. With a strong focus on enhancing customer experiences.',
        duration: '8 Weeks',
        schedule: 'Twice a week',
        link: '/ProductDesignPage'

    },
    {
        id: 5,
        image: GraphicDesign,
        course: 'Graphic Design',
        desc: 'Unlock your creative potential and learn how to bring ideas to life through visual design. This course is designed to equip students with the skills and knowledge needed to create compelling designs for print, digital media, and beyond.',
        duration: '3 Months',
        schedule: 'Twice a week',
        link: '/GraphicDesignPage'

    },
]

export default whatWeDoCardData;