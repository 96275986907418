import React from 'react'
import Footer from '../Components/Footer'
import '../Styles/privacyPolicyPage.css'

function PrivacyPolicyPage() {
  return (
    <div className='privacy-policy-container'>
        <div className="privacy-policy-container-text">
            <h1>Privacy Policy</h1>
            <h4>Updated: 29-11-2024</h4>
            <p>Pearlcity Hub is located at:</p>
            <p>15 Bori Road, Rumuibekwe,</p>
            <p>Port Harcourt, Rivers State, Nigeria.</p>
            <p>+234 906 561 6298</p>
        </div>     

        <div className="policy-content">
            <p>At Pearlcity Hub, we are committed to respecting and safeguarding your privacy in relation to any information collected through the operation of our website. This Privacy Policy applies to our website,  <a href="https://pearl-itcity.com/">www.pearl-itcity.com</a> (referred to as “we,” “us,” or “our”). It outlines the types of information collected, how it is used, and the conditions under which it may be disclosed to third parties.</p>
            <p>This Privacy Policy exclusively governs information gathered through our website and does not extend to data collected from other sources. Alongside our Terms of Service, this policy establishes the general guidelines for using our website. Depending on your interactions, you may be required to accept additional terms.</p>
            <ol>
                <li>Website Visitors</li>
                <p>Similar to most websites, Pearlcity Hub collects non-personally identifiable information such as browser type, language preference, referring website, and the date and time of visitor requests. This information helps us better understand website usage trends. Periodically, we may publish aggregated data, such as usage reports.</p>
                <p>We also collect potentially personally identifiable information, such as Internet Protocol (IP) addresses, from users who log in or leave comments on our blog. These IP addresses are disclosed under the same conditions as personally identifiable information, as detailed below.</p>
                <li>Collection of Personally Identifiable Information</li>
                <p>Certain website interactions may require us to collect personally identifiable information. For instance, visitors leaving comments may be asked to provide a username and email address. The amount and type of information collected depend on the nature of the interaction.</p>
                <li>Data Security</li>
                <p>We take the security of your personal information seriously and employ commercially reasonable methods to protect it. However, no method of data transmission or electronic storage is completely secure. While we strive to protect your data, we cannot guarantee absolute security.</p>
                <li> External Links</li>
                <p>Our website may include links to third-party websites not operated by us. Clicking these links will direct you to the respective third-party site, whose privacy policies and practices we do not control. We strongly recommend reviewing their privacy policies and terms before interacting with their content.</p>
                <li>Disclosure and Protection of Personally Identifiable Information</li>
                <p>Pearlcity Hub shares potentially personally identifiable and personally identifiable information only with employees, contractors, and affiliated organizations that need access to process it on our behalf or provide website services. These parties are bound by confidentiality agreements and may be located outside your country. By using our website, you consent to the transfer of your data as described.</p>
                <ul>
                    <p>We do not rent or sell your information. Disclosure occurs only under the following circumstances:</p>
                    <li>In response to legal obligations such as subpoenas or court orders</li>
                    <li>To protect Pearlcity Hub’s rights, property, or public interest, when deemed necessary in good faith.</li>
                </ul>
                <p>Registered users who provide email addresses may occasionally receive communications about updates, new features, or feedback requests. While such emails are infrequent, we may use blog updates as our primary communication channel.</p>
                <p>In cases where you contact us for support or feedback, we reserve the right to publish your request (without disclosing personal information) to improve our services or assist others.</p>
                <p>We take all reasonable measures to protect your data against unauthorized access, alteration, or destruction.</p>
                <li>Changes to this Privacy Policy</li>
                <p>Pearlcity Hub reserves the right to update this Privacy Policy at its discretion. While most changes are expected to be minor, we encourage users to review this page regularly for updates. Continued use of our website following any changes constitutes acceptance of the revised policy.</p>
            </ol>
            <p>If you have any questions about this Privacy Policy, please contact us at <span>info@pearl-itcity.com</span></p>
        </div>
        <Footer/>

        
    </div>
  )
}

export default PrivacyPolicyPage