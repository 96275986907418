import { Route, Routes } from 'react-router-dom';
import './App.css';
import Nav from './Components/Nav';
import Home from './Pages/Home.jsx'
import Browse from './Pages/Browse.jsx'
import Student from './Pages/Student.jsx'
import Business from './Pages/Business.jsx'
import About from './Pages/AboutUs.jsx'
import FaqPage from './Pages/FaqPage.jsx'
import Contact from './Pages/Contact.jsx'
import FrontEndPage from './Pages/FrontEndPage.jsx';
import BackEndPage from './Pages/BackEndPage.jsx';
import ProductDesignPage from './Pages/ProductDesignPage.jsx';
import FullStackPage from './Pages/FullStackPage.jsx';
import FrontendApplyPage from './Pages/FrontendApplyPage.jsx'
import BackendApplyPage from './Pages/BackendApplyPage.jsx'
import FullstackApplyPage from './Pages/FullstackApplyPage.jsx'
import ProductDesignApplyPage from './Pages/ProductDesignApplyPage.jsx'
import ScrollToTop from './Components/ScrollToTop.jsx';
import OnlinePage from './Pages/OnlinePage.jsx'
import BusinessFormPage from './Pages/BusinessFormPage.jsx';
import PrivacyPolicyPage from './Pages/PrivacyPolicyPage.jsx';
import TermsPage from './Pages/TermsPage.jsx'
import ProductDesignSyllabusPage from './Pages/ProductDesignSyllabusPage.jsx';
import FrontendSyllabusPage from './Pages/FrontendSyllabusPage.jsx';
import BackendSyllabusPage from './Pages/BackendSyllabusPage.jsx';
import FullstackSyllabusPage from './Pages/FullstackSyllabusPage.jsx';
import GraphicDesignPage from './Pages/GraphicDesignPage.jsx'
import GraphicDesignApplyPage from './Pages/GraphicDesignApplyPage.jsx'


function App() {
  return (
    <div className="App"> 
    <ScrollToTop/>
    <Nav/>
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/Student' element={<Student/>}/>
      <Route path='/Business' element={<Business/>}/>
      <Route path='/About' element={<About/>}/>
      <Route path='/Browse' element={<Browse/>}/>
      <Route path='/FaqPage' element={<FaqPage/>}/>
      <Route path='/Contact' element={<Contact/>}/>
      <Route path='/FrontEndPage' element={<FrontEndPage/>}/>
      <Route path='/BackEndPage' element={<BackEndPage/>}/>
      <Route path='/ProductDesignPage' element={<ProductDesignPage/>}/>
      <Route path='/FullStackPage' element={<FullStackPage/>}/>
      <Route path='/FrontendApplyPage' element={<FrontendApplyPage/>}/>
      <Route path='/BackendApplyPage' element={<BackendApplyPage/>}/>
      <Route path='/FullstackApplyPage' element={<FullstackApplyPage/>}/>
      <Route path='/ProductDesignApplyPage' element={<ProductDesignApplyPage/>}/>
      <Route path='/FrontendSyllabusPage' element={<FrontendSyllabusPage/>}/>
      <Route path='/BackendSyllabusPage' element={<BackendSyllabusPage/>}/>
      <Route path='/FullstackSyllabusPage' element={<FullstackSyllabusPage/>}/>
      <Route path='/ProductDesignSyllabusPage' element={<ProductDesignSyllabusPage/>}/>
      <Route path='/OnlinePage' element={<OnlinePage/>}/>
      <Route path='/BusinessFormPage' element={<BusinessFormPage/>}/>
      <Route path='/PrivacyPolicyPage' element={<PrivacyPolicyPage/>}/>
      <Route path='/TermsPage' element={<TermsPage/>}/>
      <Route path='/GraphicDesignPage' element={<GraphicDesignPage/>}/>
      <Route path='/TermsPage' element={<TermsPage/>}/>
      <Route path='/GraphicDesignApplyPage' element={<GraphicDesignApplyPage/>}/>

    </Routes>   
    
      
    </div>
  );
}

export default App;
