import React from 'react'
import WhatWeDo from '../Components/WhatWeDo'
import Calendar from '../Components/Calendar'
import WhyUs from '../Components/WhyUs.jsx'
import Footer from '../Components/Footer.jsx'

function Student() {
  return (
    <div className='student-container'>
        <WhatWeDo/>
        <Calendar/> 
        <WhyUs/> 
        <Footer/>
        
    </div>
  )
}

export default Student