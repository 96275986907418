import React from 'react'
import Footer from '../Components/Footer'
import '../Styles/termsPage.css'

function TermsPage() {
  return (
    <div className='terms-page-container'>
      <div className="terms-page-text">
        <h4>Pearlcity Hub</h4>
        <h1>Terms of Use</h1>
      </div>
      <div className="terms-contents">
        <p>These Terms of Use define the rules and regulations governing your access and use of Pearlcity Hub’s website. By accessing this website, you confirm your acceptance of these terms in full. If you do not agree with any part of these terms, please discontinue your use of the website.</p>
        <h3>Terminology</h3>
        <ul>
            <p>The following terms are used throughout this agreement:</p>
            <li><span>"Client," "You," "Your": </span>Refers to the individual accessing the website and accepting these terms.</li>
            <li><span>"The Company," "We," "Us," "Our":  </span> Refers to Pearlcity Hub.</li>
            <li><span>"Party," "Parties": </span>Refers collectively or individually to the Client and the Company.</li>
        </ul>
        <p>These terms govern all interactions and agreements between the Client and the Company, including the provision of services or products, in accordance with applicable Nigerian laws. Terminology such as singular/plural, capitalization, or gendered pronouns is interchangeable and holds the same meaning.</p>
        <h3>License</h3>
        <p>All intellectual property on Pearlcity Hub’s website is owned by Pearlcity Hub and/or its licensors unless explicitly stated otherwise. These rights are fully reserved, and you may only view or print pages from the website for personal use, subject to the restrictions outlined in these terms.</p>
        <ol>
          <p>You must NOT:</p>
          <li>Republish material from https://pearl-itcity.com</li>
          <li>Sell, rent, or sublicense material from the website.</li>
          <li>Reproduce, duplicate, or copy content from the website.</li>
          <li>Redistribute content, unless explicitly stated that redistribution is allowed.</li>
        </ol>
        <h3>Iframes</h3>
        <p>Creating Iframes around any page or altering the visual presentation of the website is prohibited without prior written permission.</p>
        <h3>Content Liability</h3>
        <p>We are not responsible for content that appears on your website. You agree to indemnify and defend us against any claims arising from your use of our content. Links on your website must not include content that is libelous, obscene, criminal, or violates any third-party rights.</p>
        <h3>Reservation of Rights</h3>
        <p>We reserve the right to request the removal of any or all links to our website at any time. You agree to comply promptly with such requests. We may also amend these terms or our linking policies at any time, and continued linking to our website constitutes agreement to these changes.</p>
        <h3>Removal of Links</h3>
        <p>If you find any links on our website objectionable, you may contact us. While we will review such requests, we are under no obligation to remove links or respond directly.</p>
        <h3>Accuracy and Availability</h3>
        <p>While we strive to ensure the accuracy and completeness of the information on this website, we do not guarantee it. We also do not commit to maintaining constant website availability or keeping all materials up to date.</p>
        <h3>Disclaimer</h3>
        <p>To the fullest extent permitted by law, we disclaim all warranties and conditions relating to this website, including implied warranties of satisfactory quality, fitness for purpose, and reasonable care.</p>
        <p>Nothing in this disclaimer will:</p>
        <ol>
          <li>Limit or exclude liability for death or personal injury caused by negligence.</li>
          <li>Limit or exclude liability for fraud or fraudulent misrepresentation.</li>
          <li>Limit any liability in ways not permitted under applicable law.</li>
          <li>Exclude any liability that cannot be excluded under applicable law.</li>
        </ol>
        <p>The limitations and exclusions outlined here apply to all liabilities arising under these terms, including contractual, tort-based (negligence), or statutory liabilities.</p>
        <p>As the website and its services are provided free of charge, Pearlcity Hub will not be liable for any loss or damage of any nature.</p>
        <p>For further questions, please contact us at : info@pearl-itcity.com</p>

      </div>
      <Footer/>
     

    </div>
  )
}

export default TermsPage