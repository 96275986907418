import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Components/Footer';
import '../Styles/productDesignSyllabusPage.css'

function ProductDesignSyllabusPage() {
  const [productDesignForm, setProductDesignForm] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    tel: '',
    terms: false, // Track the checkbox state
  });

  // Update form state on input change
  const updateProductDesignForm = (e) => {
    const { name, value, type, checked } = e.target;
    setProductDesignForm((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value, // Handle checkbox separately
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate checkbox
    if (!productDesignForm.terms) {
      alert('Please agree to the terms and conditions before proceeding.');
      return;
    }

    // Proceed with form submission logic
    console.log('Form submitted successfully:', productDesignForm);
    alert('Form submitted successfully!');
  };

  return (
    <div className="product-design-syllabus-page-container">
      <div className="product-design-syllabus-text">
        <h3>
          <span>Pearlcity Hub</span> Product Design (UI/UX) Course Outline
        </h3>
        <p>Retrieve the Course Schedule</p>
      </div>
      <div className="product-design-syllabus-form">
        <p>Fill out the form below to receive your syllabus.</p>
        <form onSubmit={handleSubmit}>
          <input
            onChange={updateProductDesignForm}
            type="text"
            name="firstName"
            placeholder="First Name"
            required
          />
          <input
            onChange={updateProductDesignForm}
            type="text"
            name="middleName"
            placeholder="Middle Name"
          />
          <input
            onChange={updateProductDesignForm}
            type="text"
            name="lastName"
            placeholder="Last Name"
            required
          />
          <input
            onChange={updateProductDesignForm}
            type="email"
            name="email"
            placeholder="Valid Email Address"
            required
          />
          <input
            onChange={updateProductDesignForm}
            type="tel"
            name="tel"
            placeholder="Phone Number"
            pattern="\d*"
            required
          />

          <div className="product-design-terms">
            <input
              onChange={updateProductDesignForm}
              type="checkbox"
              name="terms"
              id="terms" 
            />
            <label htmlFor="terms"> {' '}
              I consent to Pearlcity Hub's use of my data as outlined in their{' '}
              <Link to="/TermsPage">Terms of Use</Link> and{' '}
              <Link to="/PrivacyPolicyPage">Privacy Policy</Link>.
            </label>
          </div>

          <input type="submit" value="Get Course Outline" />
        </form>
      </div>
      <Footer/>
    </div>
  );
}

export default ProductDesignSyllabusPage;
