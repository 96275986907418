import React from 'react'
import CallToAction from '../Components/CallToAction'
import Footer from '../Components/Footer'
import '../Styles/contact.css'

function Contact() {
  return (
    <div className='contact-container'>
      <div className="contact-text">
        <h2>Get in Touch with Us</h2>
        <p>Have a question or need more information? We're here to help!</p>

        <h5>Address:</h5>
        <p>15 Bori Road, Rumuibekwe, 
        Port Harcourt.</p>
        <h5>Tel:</h5>
        <p>+234 906 561 6298</p>
        <h5>Email:</h5>
      
        <p>info@pearl-itcity.com</p>
        <p>frontdesk@pearl-itcity.com</p>
        <p>manager@pearl-itcity.com</p>
        <p>fullstack@pearl-itcity.com</p>
        <p>frontend@pearl-itcity.com</p>
        <p>backend@pearl-itcity.com</p>
      </div>
    
    <CallToAction/>
    <Footer/>


        
    </div>
  )
}

export default Contact