import React, { useState } from 'react'
import Footer from '../Components/Footer'
import '../Styles/productDesignApplyPage.css'
import { useRef } from "react";
import emailjs from 'emailjs-com';

function ProductDesignApplyPage() {
  const [formInput, setFormInput] = useState({
      'firstName': '',
      'middleName': '',
      'lastName': '',
      'email': '',
      'tel': '',
      'gender' : '',
      'information': '',
  })

  const updateFormInput = (e)=> {
        setFormInput({
          ...formInput,
          [e.target.name]: e.target.value
        })
  }

  const handleCheck = (e) => {
    const id = e.target.value;
    setFormInput((prevState)=>(
      {
        ...prevState,
        gender : id,     
      }
    ))
  }

  const [isLoading, setIsLoading] = useState(false)
  setTimeout(() => { setIsLoading(false); }, 9000)

  const form = useRef()
    const sendEmail = (e) => {
        setIsLoading(true);
        e.preventDefault();
    
        emailjs.sendForm('service_v2a0sb8', 'template_723r88q', form.current, 'OfX5R23pUISH3rJna')
          .then((result) => {
            alert("Message Sent")   

          }, (error) => {
            alert("Message NOT Sent")             
          });

          e.target.reset()
  }

  return (
    <div className='product-design-apply-page-container'>
      <div className="product-design-apply-text">
        <h2>UI/UX Design and Prototyping (Product Design) </h2>
        <p>Take your UI/UX Design and Prototyping skills to the next level with Pearlcity Hub</p>
      </div>
      <div className="product-design-apply-form">
        <h4>Fill out the form below to get started</h4>
        <form ref={form} onSubmit={sendEmail}>
          <div className="first-section">
          <p>First Name:</p>
          <input onChange={updateFormInput} type="text" name='firstName' placeholder='Insert first name' required />
          <p>Middle Name: </p>
          <input onChange={updateFormInput} type="text" name='middleName' placeholder='Insert middle name'  />
          <p>Last Name:</p>
          <input onChange={updateFormInput} type="text" name='lastName' placeholder='Insert last name' required />
          <p>Email:</p>
          <input onChange={updateFormInput} type="email" name='email' placeholder='Insert valid email' required />
          <p>Phone Number:</p>
          <input onChange={updateFormInput} type="tel" name='tel' placeholder='Insert phone number' required />
          </div>
             
        <div className="second-section">
        <p>Gender:</p>
        <div className="male">
        <input onChange={handleCheck} type="checkbox" name='gender' value='male' id='male' checked = {formInput.gender ==='male'} />
        <label htmlFor='male'> Male</label>
        </div>
        <div className="female">
        <input onChange={handleCheck} type="checkbox" name='gender' value='female' id='female' checked={formInput.gender ==='female'} />
        <label htmlFor='female'> Female</label>
        </div>
        <div className="NaN">
        <input onChange={handleCheck} type="checkbox" name='gender' id='NaN' value='Prefer not to say' checked={formInput.gender === 'Prefer not to say'}/>
        <label htmlFor='NaN'> Prefer not to say</label>
        </div>
       
        </div>
          
        <div className="third-section">
           <p>How did you hear about Pearlcity Hub</p>
           <div className="family">
           <input onChange={updateFormInput} type="checkbox" id='family' name='information' value='family' /> 
           <label htmlFor='family'> Family & Friends </label>
            </div> 
            <div className="instagram">
            <input onChange={updateFormInput} type="checkbox" id='instagram' name='information' value='instagram' /> 
            <label htmlFor='instagram'> Instagram </label>
            </div>
            <div className="x">
            <input onChange={updateFormInput} type="checkbox" value='x' id='x' name='information' /> 
            <label htmlFor='x'> X </label>
            <div className="facebook">
            <input onChange={updateFormInput} type="checkbox" id='facebook' name='information' value='facebook'/> 
            <label htmlFor='facebook'> Facebook </label>
            </div>
            <div className="linkedin">
            <input onChange={updateFormInput} type="checkbox" id='linkedin' name='information' value='linkedin' /> 
            <label htmlFor='linkedin'> LinkedIn </label>
            </div>
            <div className="google">
            <input onChange={updateFormInput} type="checkbox" id='google' name='information' value='google' /> 
            <label htmlFor='google'> Google </label>
            </div>
            <div className="youtube">
            <input onChange={updateFormInput} type="checkbox" name='information' id='youtube' value='youtube' /> 
            <label htmlFor='youtube'> YouTube </label>
            </div>

            </div>

           
          </div>

          <input className='form-submit' type="submit" value={isLoading ? "Submitting..." : "Submit"}  />


        </form>
      
      </div>

      <Footer/>
 
    </div>
  )
}

export default ProductDesignApplyPage