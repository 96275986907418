import React from 'react'
import { Link } from 'react-router-dom'
import Frontend from '../Assets/graphic-design.jpg'
import DurationIcon from '../Assets/duration.svg'
import DateIcon from '../Assets/date-1.svg'
import LevelIcon from '../Assets/level.svg'
import RequiredIcon from '../Assets/required.svg'
import ProjectIcon from '../Assets/project.svg'
import CostIcon from '../Assets/bill.svg'
import ScheduleIcon from '../Assets/schedule.svg'
import LearnIcon from '../Assets/learn.svg'
import AboutIcon from '../Assets/about.svg'
import CourseIon from '../Assets/course.svg'
import '../Styles/graphicDesignPage.css'
import Expectation from '../Components/Expectation.jsx'
import Faq from '../Components/Faq.jsx'
import Footer from '../Components/Footer.jsx'
import GraphicDesignCallToAction from '../Components/GraphicDesignCallToAction.jsx'


function GraphicDesignPage() {

  return (
    <div className='graphic-design-page-container'>
        <div className="graphic-design-image">
            <img src={Frontend} alt="graphic-design-image" />
        </div>
        <div className="graphic-design-text">
            <h2>Graphic Design <span>(with CorelDraw and Photoshop)</span></h2>
            <p>Unlock your creative potential and learn how to bring ideas to life through visual design. This course is designed to equip students with the skills and knowledge needed to create compelling designs for print, digital media, and beyond.</p>
            <div className="graphic-design-buttons">
                <button className='enrol'> 
                    <Link to ='/GraphicDesignApplyPage'> Join Us Now</Link>
                </button>
                {/* <button className='syllabus'> 
                    <Link to ='/FrontendSyllabusPage'> Download Syllabus</Link>
                </button> */}
            </div>
            <div className="graphic-design-status">
                <h5>Application Ongoing...</h5>
            </div>
        </div>
        <div className="graphic-design-details">
            <div className="timeframe">
                <img src= {DurationIcon}alt="duration-icon" />
                <p>Timeframe</p>
                <h3>3 Months</h3>
                <p>Twice a Week</p>
            </div>
            <div className="date">
                <img src={DateIcon} alt="date-icon" />
                <p>Start Date</p>
                <h3>13th January, 2025</h3>
            </div>
            <div className="level">
                <img src={LevelIcon} alt="level-icon"/>
                <p>Level</p>
                <h3>Beginner</h3>
            </div>
            <div className="required">
                <img src={RequiredIcon} alt="required-icon" />
                <p>Pre-requisites</p>
                <h3>A Laptop</h3>
            </div>
            <div className="project">
                <img src={ProjectIcon} alt="project-icon" />
                <p>Projects</p>
                <h3>1 Project</h3>
            </div>
            <div className="cost">
                <img src={CostIcon} alt="cost-bill-icon" />
                <p>Cost</p>
                <h3>N350,000 (Flexible Payment)</h3>
            </div>


        </div>
        <div className="what-to-know">
            <div className="about-course">
                <div className="about-heading">
                    <img src={AboutIcon} alt="about-icon" />
                    <h2>About the Course</h2>
                </div>
                <div className="about-text">
                    <p>This course is designed to provide a comprehensive introduction to the world of graphic design. Whether you're a beginner or looking to sharpen your creative skills, this program will teach you the core principles and tools needed to create stunning visual content.</p>

                </div>

            </div>
            <div className="what-to-learn">
                <div className="what-to-learn-heading">
                    <img src={LearnIcon} alt="learning-icon" />
                    <h2>What you will Learn</h2>
                </div>
                <div className="what-to-learn-text">
                    <p>This course combines theory with practical exercises, ensuring you not only learn the concepts but also apply them effectively. By the end of the program, you’ll have the confidence and expertise to bring your creative ideas to life. You will learn:</p>
                    <ul>
                        <li>Foundation and Fundamentals</li>
                        <li> Typography and Layout Design</li>
                        <li>Advanced Techniques and Project Work
                        </li>
                    </ul>
                </div>

            </div>
            <div className="prerequisites">
                <div className="prerequisites-heading">
                    <img src={RequiredIcon} alt="required-icon" />
                    <h2>Pre-requisites</h2>
                </div>
                <div className="prerequisites-text">
                    <p>For effective learning experiences, the following are required:</p>
                    <ul>
                        <li>Access to a computer – Mac or Windows.</li>
                        <li> Little or no previous Graphic Design experience.</li>
                        <li>Little or no previous knowledge of CorelDraw and Photoshop</li>
                        <li>Laptop Specifications(8gb-16gb RAM, 256SSD or 500gb)</li>
                        <li>Willingness to learn.</li>
                    </ul>
                </div>

            </div>
            <div className="course-outline">
                <div className="course-outline-heading">
                    <img src={CourseIon} alt="course-icon" />
                    <h2>Course Outline:</h2>
                </div>
                <div className="course-outline-text">
                    <p>The following are our course outline:</p>
                    <ul>
                      <h3><span>Week 1-2: </span> Introduction to Design Principles
                      </h3>
                        <li>Understanding Design Elements: Line, Shape, Color, Texture, Space
                        </li>
                        <li>Principles of Design: Balance, Rhythm, Emphasis, Proportion, Contrast
                        </li>
                        <li>Introduction to Color Theory: Color Wheels, Color Schemes, Color Psychology
                        </li>
                        <li>Design Software Overview: Adobe Photoshop and Illustrator and/or CorelDraw
                        </li>
                      <h3><span>Week 3-4: </span> Digital Art Fundamentals
                      </h3>
                        <li>Basic Tools and Interface of Adobe Photoshop
                        </li>
                        <li>Image Editing Techniques: Cropping, Resizing, Rotating, Flipping
                        </li>
                        <li>Layer Management: Understanding Layers, Groups, Blending Modes
                        </li>
                        <li>Color Correction and Adjustment: Levels, Curves, Hue/Saturation
                        </li>
                        <li>Creating Digital Paintings and Illustrations
                        </li>
                      <h3><span>Week 5-6: </span> Typography and Layout Design
                      </h3>
                        <li>Understanding Fonts: Serif, Sans-Serif, Script, and Display Fonts
                        </li>
                        <li>Typography Basics: Font Pairing, Kerning, Leading, Tracking
                        </li>
                        <li>Creating Text Effects: Warping, Distorting, 3D Effects
                        </li>
                        <li>Typography in Design: Layout, Hierarchy, Readability
                        </li>
                      
                      <h3><span>Week 7-8: </span> Layout Design</h3>
                        <li>Principles of Layout Design: Grid Systems, Balance, and Alignment
                        </li>
                        <li>Creating Brochures and Flyers
                        </li>
                        <li>Designing Business Cards and Letterheads
                        </li>
                        <li>Designing Social Media Graphics
                        </li>
                        <li>Creating Infographics
                        </li>
                      <h3><span>Week 9-10: </span> Advanced Photoshop Techniques</h3>
                        <li>Masking and Clipping Masks
                        </li>
                        <li>Smart Objects
                        </li>
                        <li>Filters and Effects
                        </li>
                        <li>Creating Photorealistic Images
                        </li>
                    <h3><span>Week 11-12: </span>  Advanced Illustrator and / or CorelDraw Techniques
                    </h3>
                        <li>Vector Graphics and Paths
                        </li>
                        <li>Creating Complex Shapes and Patterns
                        </li>
                        <li>Designing Logos and Branding
                        </li>   
                        <li>Sources of Design Inspiration and Assets</li>
                        <li>Creating a Portfolio with Pinterest</li>   
                                          
                    </ul>
                    <h4>Final Project</h4>
                </div>

            </div>
            <div className="class-schedule">
                <div className="class-schedule-heading">
                    <img src={ScheduleIcon} alt="schedule-icon" />
                    <h2>Class Schedule</h2>
                </div>
                <div className="class-schedule-text">
                    <p><span>Days of Class: </span> Mondays and Fridays</p>
                </div>
            </div>
        </div>
        <GraphicDesignCallToAction/>
        <Expectation/>
        <Faq/>
        <GraphicDesignCallToAction/>
        <Footer/>
       
       

    </div>
  )
}

export default GraphicDesignPage