import CurriculumIcon from '../Assets/curriculum.svg'
import ExperienceIcon from '../Assets/experience.svg'
import CommunityIcon from '../Assets/community.svg'
import InternshipIcon from '../Assets/internship.svg'
import TuitionIcon from '../Assets/tuition.svg'

const WhyUs = [
    {
        id: 1,
        icon: CurriculumIcon,
        title: 'Industry-Aligned Curriculum',
        desc: 'Our robust training programs feature a hands-on curriculum designed to transform you from a beginner into a highly skilled professional.'
    },
    {
        id: 2,
        icon: ExperienceIcon,
        title: 'Practical Learning Approach',
        desc: 'Gain real-world expertise by working on hands-on projects, equipping you with the skills to excel in tomorrow’s workforce.'
    },
    {
        id: 3,
        icon: CommunityIcon,
        title: 'Vibrant Tech Network',
        desc: 'Join our thriving community of over 35,000 tech professionals, where you’ll stay updated on industry trends, access valuable resources, discover job opportunities, and forge lasting connections with like-minded peers.'
    },
    {
        id: 4,
        icon: InternshipIcon,
        title: 'Career Advancement Opportunities',
        desc: 'Upon completing your training, gain access to internships and job placements to kickstart or elevate your career.'
    },
    {
        id: 5,
        icon: TuitionIcon,
        title: 'Flexible Payment Plans',
        desc: 'Our adaptable tuition payment options ensure you can focus on learning without financial stress.'
    },

]



export default WhyUs;