import React from 'react'
import { Link } from 'react-router-dom'
import '../Styles/uiCallToActionl.css'

function UiCallToAction() {
  return (
    <div className='ui-call-to-action-container'>
        <h2>UI/UX Design and Prototyping (Product Design)</h2>
        <p>UI and UX design skills are highly sought after and versatile, spanning various products and industries. With a strong focus on enhancing customer experience.</p>
        <div className="ui-buttons">
                <button className='enrol'> 
                    <Link to ='/ProductDesignApplyPage'> Join Us Now</Link>
                </button>
                {/* <button className='syllabus'> 
                    <Link to ='/ProductDesignSyllabusPage'> Download Syllabus</Link>
                </button> */}
            </div>
    </div>
  )
}

export default UiCallToAction