import React from 'react'
import { useState } from 'react'
import Logo from '../Assets/pearlcity-logo.png'
import Hamburger from '../Assets/ham.svg'
import Close from '../Assets/close.svg'
import { Link } from 'react-router-dom'
import '../Styles/nav.css'


function Nav() {
    const [showNav, setShowNav] = useState(false)
    const [activeLink, setActiveLink] = useState(1)

    const navToggler = ()=> {
        setShowNav(!showNav)    }

    const removeNav = (index)=> {
        setShowNav(false)
        setActiveLink(index)
    }
   
    
  return (
    <div className='nav-container'>
        <div className="logo">
            <Link to='/'>
             <img  onClick={removeNav} src={Logo} alt="baansure-logo" /></Link>
           
        </div>
        <div className="hamburger">
            {!showNav && <img onClick={navToggler} src={Hamburger} alt="hamburger" />}
            {showNav && <img onClick={navToggler} className='nav-close' src={Close} alt="hamburger" />}
        </div>
        <div className= {showNav? 'mobile-view':'desktop-view'}>
            <Link className={activeLink === 1? 'active-link': ''}  onClick={()=>removeNav(1)} to='/'>Home</Link>
            <Link className={activeLink === 2? 'active-link': ''} onClick={()=>removeNav(2)} to='/Student'>Individual</Link>            
            <Link className={activeLink === 3? 'active-link': ''}  onClick={()=>removeNav(3)} to='/Business'>Business</Link>
            <Link className={activeLink === 4? 'active-link': ''} onClick={()=>removeNav(4)} to='/About'>About Us</Link>
            <Link className={activeLink === 5? 'active-link': ''} onClick={()=>removeNav(5)} to='/OnlinePage'>Online</Link>       
            <Link className='browse' onClick={()=>removeNav(6)} to='/Browse'>Browse Courses</Link>
          
           
        </div>      

    </div>





  )
}

export default Nav;