const CalendarData = [
    {
        id: 1,
        heading: 'January Cohort 2025',
        date: 'Jan 13th – March 10th',
        holiday: 'March 1st, 5th & 8th'
    },
    {
        id: 2,
        heading: 'March Cohort 2025',
        date: 'March 17th – June 16th',
        holiday: 'March 31st,  April 1st, 18th, May 1st, June 12th'
    },
    {
        id: 3,
        heading: 'June Cohort 2025',
        date: 'June 23rd –  1st August',
        holiday: 'June 27th'
    },
    {
        id: 4,
        heading: 'August Cohort 2025',
        date: 'August 5th – October 14th',
        holiday: 'Sep 5th, Oct 1st'
    },
    {
        id: 5,
        heading: 'October Cohort 2025',
        date: 'October 16th – December 23rd',
        holiday: 'September 16th, 17th, Oct 1st'
    },

]

export default CalendarData;