import React, { useEffect } from 'react'
import { useState } from 'react';
import '../Styles/testimonial.css'

function Testimonial() {

    const [completionRate, setCompletionRate] = useState(0)
    const [numbers, setNumbers] = useState(0)
    const [partners, setPartners] = useState(0)

    
    // Simulate counting with a delay
    useEffect(() => {
  
        const intervalId = setInterval(() => {
          if (completionRate < 98) {
            setCompletionRate(completionRate + 1);
          }
        }, 10); // Adjust delay for faster/slower counting (in milliseconds)
    
        // Cleanup function to prevent memory leaks
        return () => clearInterval(intervalId);
      }, [completionRate]); 

      useEffect(() => {
  
        const intervalId = setInterval(() => {
          if (numbers < 100) {
            setNumbers(numbers + 1);
          }
        }, 1); // Adjust delay for faster/slower counting (in milliseconds)
    
        // Cleanup function to prevent memory leaks
        return () => clearInterval(intervalId);
      }, [numbers]); 
      useEffect(() => {
  
        const intervalId = setInterval(() => {
          if (partners < 20) {
            setPartners(partners + 1);
          }
        }, 10); // Adjust delay for faster/slower counting (in milliseconds)
    
        // Cleanup function to prevent memory leaks
        return () => clearInterval(intervalId);
      }, [partners]); 

  return (
    <div className='testimonial-container'>
         <p>Strength in Numbers</p>
        <div className="numbers">
         
            <div className="completion">
                <h2>{completionRate}%</h2>
                <h6>Our programs boast a remarkable 98% completion rate </h6>
            </div>
            <div className="graduates">
                <h2>{numbers}+</h2>
                <h6>Over 100 individuals have completed our programs </h6>
            </div>
            <div className="partners">
                <h2>{partners}+</h2>
                <h6>We collaborate with over 20 industry partners</h6>
            </div>

         </div>
        <div className="our-students">
            <h2><span>Proven Career Success:</span> More than 90% of our graduates secure high-paying jobs within six months of completing their training.</h2>
            <div className="our-student-card">
            <p>Pearlcity Hub is a premier tech academy committed to accelerating the growth of tech talent across Africa to meet the increasing demands of businesses and startups in Nigeria and worldwide.</p>
            <p>We utilize an Agile, project-based learning model that emphasizes mastering in-demand industry skills.</p>
            <p>In the past years, we’ve trained over 100 developers, with more than 50 successfully securing gainful employment.</p>
            </div>
            
        </div>
        
       
    </div>
  )
}

export default Testimonial