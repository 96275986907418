import React, { useState } from 'react'
import '../Styles/whyUs.css'
import WhyUsImg from '../Assets/tech-background.jpg'
import DownAngle from '../Assets/angle-down.svg'
import UpAngle from '../Assets/angle-up.svg'
import WhyUsData from '../Data/WhyUsData.js'

function WhyUs() {
    const [showContent, setShowContent] =useState(false)
    const [content,setContent] = useState(null)

    const handleContent = (index)=> {
        setShowContent (!showContent);
        setContent(index)
    }
    console.log(showContent);
    
    
  return (
    <div className='why-us-container'>
        <div className="why-us-text">
            <h2>Why Choose Us</h2>
            <p>At our tech hub, we are committed to transforming your career aspirations into reality.</p>
        </div>
        <div className="why-us-section">
            <img className='why-us-image' src={WhyUsImg} alt="tech-background" />
            <div className="why-us-card">
            {WhyUsData.map((item)=>(
           
                <div key={item.id}  onClick={()=> handleContent(item.id)} className="title">
                    <div className="heading">
                    <img src={item.icon}alt="service-icon" />
                    <h3>{item.title}</h3>
                    <div className="angle-arrow">
                        {showContent && item.id === content ? <img onClick={handleContent} src={DownAngle} alt="down-angle" />:<img onClick={handleContent} src={UpAngle} alt="up-angle" /> }
                   
                     </div>

                    </div>
                  
                     <div className="descriptions">
                    {content === item.id && showContent === true ? <p>{item.desc}</p>:''}
                  </div>
                
                </div>
            
                
               
               
     
        ))} 
           </div>
        </div>
       
        
    </div>
  )
}

export default WhyUs