import React from 'react'
import { Link } from 'react-router-dom'
import UiImage from '../Assets/ui.jpg'
import DurationIcon from '../Assets/duration.svg'
import DateIcon from '../Assets/date-1.svg'
import LevelIcon from '../Assets/level.svg'
import RequiredIcon from '../Assets/required.svg'
import ProjectIcon from '../Assets/project.svg'
import CostIcon from '../Assets/bill.svg'
import ScheduleIcon from '../Assets/schedule.svg'
import LearnIcon from '../Assets/learn.svg'
import AboutIcon from '../Assets/about.svg'
import CourseIon from '../Assets/course.svg'
import '../Styles/uiPage.css'
import UiCallToAction from '../Components/UiCallToAction.jsx'
import Expectation from '../Components/Expectation.jsx'
import Faq from '../Components/Faq.jsx'
import Footer from '../Components/Footer.jsx'


function ProductDesignPage() {

  return (
    <div className='ui-page-container'>
        <div className="ui-image">
            <img src={UiImage} alt="ui-image" />
        </div>
        <div className="ui-text">
            <h2>UI/UX Design and Prototyping <span>(Product Design)</span></h2>
            <p>UI and UX design skills are highly sought after and versatile, spanning various products and industries. With a strong focus on enhancing customer experience.</p>
            <div className="ui-buttons">
                <button className='enrol'> 
                    <Link to ='/ProductDesignApplyPage'>Join Us Now</Link>
                </button>
                {/* <button className='syllabus'> 
                    <Link to ='/ProductDesignSyllabusPage'> Download Syllabus</Link>
                </button> */}
            </div>
            <div className="ui-status">
                <h5>Application Ongoing...</h5>
            </div>
        </div>
        <div className="ui-details">
            <div className="timeframe">
                <img src= {DurationIcon}alt="duration-icon" />
                <p>Timeframe</p>
                <h3>8 Weeks</h3>
                <p>Twice a Week</p>
            </div>
            <div className="date">
                <img src={DateIcon} alt="date-icon" />
                <p>Start Date</p>
                <h3>13th January, 2025</h3>
            </div>
            <div className="level">
                <img src={LevelIcon} alt="level-icon"/>
                <p>Level</p>
                <h3>Beginner</h3>
            </div>
            <div className="required">
                <img src={RequiredIcon} alt="required-icon" />
                <p>Pre-requisites</p>
                <h3>A Laptop</h3>
            </div>
            <div className="project">
                <img src={ProjectIcon} alt="project-icon" />
                <p>Projects</p>
                <h3>1 Project</h3>
            </div>
            <div className="cost">
                <img src={CostIcon} alt="cost-bill-icon" />
                <p>Cost</p>
                <h3>N200,000 (Flexible Payment)</h3>
            </div>


        </div>
        <div className="what-to-know">
            <div className="about-course">
                <div className="about-heading">
                    <img src={AboutIcon} alt="about-icon" />
                    <h2>About the Course</h2>
                </div>
                <div className="about-text">
                    <p>Comprehensive online course for creating digital products.</p>
                    <p>Every session is conducted live and guided by our industry professionals.</p>
                    <p>Master design thinking, user experience (UX), and user interface (UI) design.</p>
                    <p>Collaborate in a small team to tackle a real-world design challenge.</p>
                    <p>Engage in discussions and network with fellow classmates.</p>
                    <p>Classes are available for Nigeria, UK, and EU time zones.</p>
                </div>

            </div>
            <div className="what-to-learn">
                <div className="what-to-learn-heading">
                    <img src={LearnIcon} alt="learning-icon" />
                    <h2>What you will Learn</h2>
                </div>
                <div className="what-to-learn-text">
                    <p>
                    You will learn how to develop a product that addresses user needs while eliminating inconsistencies and bugs.</p>
                    <p>By the end of this course you will have:</p>
                    <ul>
                        <li>Developed your understanding of the design processes</li>
                        <li>Mastered conducting user research, interview, testing, etc</li>
                        <li>Undertaken relevant research and set a design strategy</li>
                        <li>Generated ideas and developed concepts</li>
                        <li>Enhanced your drawing skills for both sketching and technical drawing</li>
                        <li>Collaborated with other students</li>
                        <li>Received personal feedback on your work from an industry expert</li>
                        <li>Communicated and presented your ideas through drawing and three-dimensional form</li>
                        <li>Completed a range of practical tasks that apply the concepts of product design</li>
                        <li>An understanding of the fundamentals of design and the processes used by professional designers, and know-how to apply similar techniques to your own work</li>
                        <li>Mastered designing for Mobile and Web</li>
                        <li>Build a project for your portfolio.</li>
                    </ul>
                    <h3>Pre-course Activity</h3>
                    <ul>
                      <li>Complete Student Profile</li>
                      <li>Introduction to Figma</li>
                      <li>Sign up to student community</li>
                    </ul>
                </div>

            </div>
            <div className="prerequisites">
                <div className="prerequisites-heading">
                    <img src={RequiredIcon} alt="required-icon" />
                    <h2>Pre-requisites</h2>
                </div>
                <div className="prerequisites-text">
                    <p>For effective learning experiences, the following are required:</p>
                    <ul>
                        <li> Access to a computer – Mac or Windows.</li>
                        <li> Little or no previous coding experience.</li>
                        <li>Laptop Specifications(8gb -16gb RAM, 256SSD or 500gb)</li>
                        <li>Willingness to learn.</li>
                    </ul>
                </div>

            </div>
            <div className="course-outline">
                <div className="course-outline-heading">
                    <img src={CourseIon} alt="course-icon" />
                    <h2>Course Outline</h2>
                </div>
                <div className="course-outline-text">
                    <p>The following are our course outline:</p>
                    <ul>
                      <h3>Week 1:</h3>
                        <li>Course Orientation</li>
                        <li>User Research landscape</li>
                        <li>Design Thinking</li>
                        <li>Benefits of User Research</li>
                        <li>Generative Research</li>
                        <li>Evaluative Research</li>
                        <li>Quantitative vs Qualitative</li>
                      <h3>Week 2:</h3>
                        <li>Survey Preparation</li>
                        <li>Stakeholder Interviews</li>
                        <li>User Interviews</li>
                        <li>Product Strategy</li>
                        <li>Competitor Analysis</li>
                        <li>Product vs Brand</li>
                      
                      <h3>Week 3:</h3>
                      <li>Developing Personas</li>
                      <li>Empathy Mapping</li>
                      <li>Performing Task Analysis</li>
                      <li>Experience Mapping</li>
                      <li>Workshop Facilitation</li>

                      <h3>Week 4:</h3>
                      <li>Writing User Stories</li>
                      <li>Jobs-To-Be-Done</li>
                      <li>Problem Statements and Hypothesis</li>
                      <li>Ideation</li>
                      <li>Storyboarding</li>
                      <li>User Flows</li>

                      <h3>Week 5:</h3>
                      <li>Information Architecture</li>
                      <li>Navigation and Site Maps</li>
                      <li>Introduction to Wireframing</li>
                      <li>Paper Prototyping</li>

                    <h3>Week 6:</h3>
                     <li>Digital Wire-framing with Figma</li>
                     <li>Visual Design Theory</li>
                     <li>Typography & Fonts</li>
                     <li>Components</li>
                     <li>Design Systems</li>
                     <li>Design Guidelines</li>
                   

                  <h3>Week 7:</h3>
                  <li>Responsive Design</li>
                  <li>Plugins</li>
                  <li>Introduction to Prototyping</li>
                  <li>Usability Testing</li>
                  <li>Addressing User Feedback</li>
                  <li>Feature Prioritization</li>


                  <h3>Week 8:</h3>
                  <li>Usability Testing</li>
                  <li>Task Scenarios</li>
                  <li>Roadmaps and Prioritisation</li>
                  <li>Storytelling and Presentation Preparation</li>    


                  <h3>Week 9:</h3>    
                  <li>Presentation Clinic</li>          
                  <li>Review of Usability Testing</li>          
                  <li>Design Critiques</li>          
                  <li>Presentation Practice</li>    

                  <h3>Week 10:</h3>      
                  <li>Collaboration</li>          
                  <li>Projects</li>       

                  <h3>Week 11:</h3>  
                  <li>Final Presentation</li>
                  <li>Stakeholder Feedback and Q&A</li>
                  <li>Course Retrospective</li>
                  <li>Review of Industry Portfolios</li>
                  <li>Next Steps</li>

                  <h3>Week 12:</h3> 
                  <li>Portfolio Clinics</li>
                  <li>Q&A Sessions with Recruiters</li>
                  <li>Workshops with Industry Experts</li>
                  <li>Career Advice and Support</li>

                </ul>                   
              </div>

            </div>
            <div className="class-schedule">
                <div className="class-schedule-heading">
                    <img src={ScheduleIcon} alt="schedule-icon" />
                    <h2>Class Schedule</h2>
                </div>
                <div className="class-schedule-text">
                    <p><span>Days of Class: </span> Mondays and Wednesdays</p>
                </div>
            </div>
        </div>
        <UiCallToAction/>
        <Expectation/>
        <Faq/>
        <UiCallToAction/>
        <Footer/>
       
       

    </div>
  )
}

export default ProductDesignPage