import React from 'react'
import '../Styles/onlinePage.css'

function OnlinePage() {
  return (
    <div className='online-container'>
        <h1>Coming Soon...</h1>
    </div>
  )
}

export default OnlinePage