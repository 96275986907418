import React from 'react'
import WhatWeDo from '../Components/WhatWeDo'
import Calendar from '../Components/Calendar'
import Footer from '../Components/Footer'
import Faq from '../Components/Faq'


function Browse() {
  return (
    <div className='browse-courses-container'>
      <WhatWeDo/>
      <Calendar/>   
      <Faq/>  
      <Footer/>  
  
    </div>
  )
}

export default Browse