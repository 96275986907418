import React from 'react'
import { Link } from 'react-router-dom'
import CorporateImage from '../Assets/corporate-photo.jpg'
import BusinessImage from '../Assets/corporate-phone-2.jpg'
import SkillIcon from '../Assets/learn copy.svg'
import TeachingIcon from '../Assets/teachers copy.svg'
import CurriculumIcon from '../Assets/curriculum copy.svg'
import CertificateIcon from '../Assets/certificate copy.svg'
import FinalCallImage from '../Assets/final-call.jpg'
import Footer from '../Components/Footer'
import '../Styles/business.css'



function Business() {
  const  CorporateData = [
    {
    id: 1,
    image: SkillIcon,
    heading: 'Our priority is equipping you with skills that are highly sought after.' 
  },
    {
    id: 2,
    image: CurriculumIcon,
    heading: 'Customized learning strategies designed specifically for your teams.' 
  },
    {
    id: 3,
    image: TeachingIcon,
    heading: 'Guided and instructed by experts from the industry.' 
  },
    {
    id: 4,
    image: CertificateIcon,
    heading: 'Earn certifications that enhance your resume.' 
  },

]

  return (
    <div className='business-container'>
        <div className="business-container-image">
          <img src={CorporateImage} alt="corporate-image" />
        </div>
        <div className="business-container-text">
          <h1><span>Equip your team</span> with the skills they need to thrive in tomorrow's workplace.</h1>
         <p>Looking to build a team that collaborates and succeeds seamlessly? Pearlcity Hub is here to bring your vision to life with our customized curriculum and cutting-edge industry technologies, designed to create the team you’ve always imagined!</p>
        </div>
        <div className="corporate-call">
          <div className="corporate-call-image">
            <img src={BusinessImage} alt="business-image" />
          </div>
          <div className="corporate-call-holder">
          <h2>Elevate Your Team’s Potential</h2>
          {CorporateData.map((item)=>(
                <div key={item.id} className="corporate-call-text">
                <img src={item.image} alt="service-images"/>
                <p>{item.heading}</p>
                
              </div>
          ))}
          </div>

      </div>
      <div className="final-call">


        <div className="final-call-image">
        <img src={FinalCallImage} alt="final-call-image" />

        </div>
       <div className="final-call-text">
       <h3>Equip your team with the skills needed to thrive in the 21st century.</h3>
        <p>Strengthen your organization with a customized tech curriculum designed to equip your team with the skills needed to address your business goals and drive success.</p>
        <button>
          <Link to ='/BusinessFormPage'>Let's Talk</Link>
        </button>
       </div>
    
      </div>
    

        <Footer/>
    </div>
  )
}

export default Business