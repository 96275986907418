import React, { useState } from 'react'
import { useRef } from "react";
import { Link } from 'react-router-dom'
import Footer from '../Components/Footer'
import emailjs from 'emailjs-com';
import '../Styles/businessFormPage.css'


function BusinessFormPage() {

  const [formInput, setFormInputs] = useState({
    'firstName': '',
    'middleName': '',
    'lastName': '',
    'institution': '',
    'email': '',
    'employees': '',
    'comment': ''
  })

  const updateForm = (e)=> {
    setFormInputs((prevInput)=> (
      {
        ...prevInput,
        [e.target.name]: e.target.value
      }
    ))
  }

  const [isLoading, setIsLoading] = useState(false)
  setTimeout(() => { setIsLoading(false); }, 9000)

  const form = useRef()
    const sendEmail = (e) => {
        setIsLoading(true);
        e.preventDefault();
    
        emailjs.sendForm('service_mwq4t3a', 'template_x381gja', form.current, 'RaAxTjSaiWa4yU-Pa')
          .then((result) => {
            alert("Message Sent")   

          }, (error) => {
            alert("Message NOT Sent")             
          });

          e.target.reset()
  }
  






  return (
    <div className='business-form-container'>
      <div className="business-form-text">
        <h2>Book a Consultation</h2>
        <ul>
          <p>Please complete the form below, and we'll arrange a call with your team to:</p>
          <li>Provide detailed information about our training program</li>
          <li>Address any questions or concerns you may have</li> 
        </ul>
        <p>We look forward to connecting with you!</p>
      </div>
      <div className="business-form-form">
        <form ref={form} onSubmit={sendEmail}>
          <input onChange={updateForm} type="text" name='firstName' placeholder='First Name'required />
          <input onChange={updateForm} type="text" name='middleName' placeholder='Middle Name'  />
          <input onChange={updateForm} type="text" name='lastName' placeholder='Last Name' required />
          <input onChange={updateForm} type="text" name='institution' placeholder='Name of Institution' required />
          <input onChange={updateForm} type="email" name='email' placeholder='Corporate Email' required />
          <select onChange={updateForm} id="dropdown" name="employees" required>
            <option value="">Number of Trainees</option>
            <option value="Less than 10">Less than 10 </option>
            <option value="More than 10">More than 10</option>
            <option value="50 and Above">50 and Above </option>
        </select>
        <textarea onChange={updateForm} name="comment" placeholder='Additional Message' rows="5" cols="30"></textarea>
        <input type="submit" value={isLoading ? "Submitting..." : "Let's Talk"}/>
        <p> I acknowledge that by submitting this form, I consent to the <Link to="/TermsPage">Terms of Use</Link> and{' '} <Link to="/PrivacyPolicyPage">Privacy Policy</Link>. of Pearlcity Hub. </p>                  

        </form>

      </div>
      <Footer/>      

    </div>
  )
}

export default BusinessFormPage