import React from 'react'
import '../Styles/grow.css'
import GrowCardData from '../Data/GrowData.js'

function Grow() {
  return (
    <div className='grow-container'>
        <div className="grow-text">
            <h2>Grow with Pearlcity Hub</h2>
        </div>
      <div className="grow-card-holder">
      {GrowCardData.map((item)=>(
            <div key={item.id} className="grow-cards">
              
              <div className="grow-card-image">
                  <img src={item.image} alt="service-images" />
              </div>
              <div className="grow-card-text">
                  <h3>{item.heading}</h3>
                  <p>{item.desc}</p>                
              </div>
          </div>
        ))}
      </div>
       
    </div>
  )
}

export default Grow