import React from 'react'
import AboutUsImage from '../Assets/about-us.jpg'
import WhoImage from '../Assets/workstation.jpg'
import CallToAction from '../Components/CallToAction'
import WhatWeDo from '../Components/WhatWeDo'
import WhyUs from '../Components/WhyUs.jsx'
import Footer from '../Components/Footer'
import '../Styles/aboutUs.css'


function AboutUs() {
  return (
    <div className='about-us-container'>
             <div className="about-us-image">
          <img src={AboutUsImage} alt="about-us-image" />
        </div>
        <div className="about-us-text">
          <h1>About Us</h1>
          <p>Welcome to Pearlcity Hub, your trusted partner in driving innovation and growth through technology. At Pearlcity Hub, we are committed to empowering individuals and businesses by providing cutting-edge tech solutions, expert training, and invaluable resources tailored to meet the evolving demands of the digital age.</p>
        </div>
        <div className="who-we-are">
          <div className="who-we-are-image">
            <img src={WhoImage} alt="who-we-are-image" />
          </div>
          <div className="who-we-are-text">
            <h2>Who We Are</h2>
            <p>Pearlcity HUB is a dynamic platform empowering individuals and organizations through technology. We bridge the gap between technology and real-world applications, offering top-tier training and support from beginner to expert, to foster technological excellence. We provide hands-on training, projects, and cohorts to help you apply your skills in real-world scenarios. </p>

            <div className="mission">
          <h3>Our Mission</h3>
          <p>Inspiring and equipping individuals and businesses with the knowledge and tools to navigate the future, prioritizing innovation, integrity, and excellence to transform potential into reality.</p>
          <h3>Our Vision</h3>
          <p>Empowering the next generation of African tech leaders and positions them as key players in shaping the global future</p>
          <h3>Tagline:</h3>
          <p>"Empowering the Next Wave of African Innovators."</p>
        </div>
          </div>  
                 
        </div>
       
              
        <WhatWeDo/>
        <CallToAction/>
        <WhyUs/>
        <CallToAction/>
        <Footer/>
    </div>
  )
}

export default AboutUs