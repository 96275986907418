import React from 'react'
import { Link } from 'react-router-dom'
import FullstackImage from '../Assets/fullstack.jpg'
import DurationIcon from '../Assets/duration.svg'
import DateIcon from '../Assets/date-1.svg'
import LevelIcon from '../Assets/level.svg'
import RequiredIcon from '../Assets/required.svg'
import ProjectIcon from '../Assets/project.svg'
import CostIcon from '../Assets/bill.svg'
import ScheduleIcon from '../Assets/schedule.svg'
import LearnIcon from '../Assets/learn.svg'
import AboutIcon from '../Assets/about.svg'
import CourseIon from '../Assets/course.svg'
import '../Styles/fullstackPage.css'
import FullstackCallToAction from '../Components/FullstackCallToAction.jsx'
import Expectation from '../Components/Expectation.jsx'
import Faq from '../Components/Faq.jsx'
import Footer from '../Components/Footer.jsx'


function FullStackPage() {

  return (
    <div className='fullstack-page-container'>
        <div className="fullstack-image">
            <img src={FullstackImage} alt="fullstack-image" />
        </div>
        <div className="fullstack-text">
            <h2>Fullstack  <span>Web Development</span></h2>
            <p>Ready to dive into the thrilling realm of full-stack development? Our all-encompassing Full-Stack Development Program opens the door to your future.</p>
            <div className="fullstack-buttons">
                <button className='enrol'> 
                    <Link to ='/FullstackApplyPage'> Join Us Now</Link>
                </button>
                {/* <button className='syllabus'> 
                    <Link to ='/FullstackSyllabusPage'> Download Syllabus</Link>
                </button> */}
            </div>
            <div className="fullstack-status">
                <h5>Application Ongoing...</h5>
            </div>
        </div>
        <div className="fullstack-details">
            <div className="timeframe">
                <img src= {DurationIcon}alt="duration-icon" />
                <p>Timeframe</p>
                <h3>6 Months</h3>
                <p>4x's a Week</p>
            </div>
            <div className="date">
                <img src={DateIcon} alt="date-icon" />
                <p>Start Date</p>
                <h3>13th January, 2025</h3>
            </div>
            <div className="level">
                <img src={LevelIcon} alt="level-icon"/>
                <p>Level</p>
                <h3>Beginner</h3>
            </div>
            <div className="required">
                <img src={RequiredIcon} alt="required-icon" />
                <p>Pre-requisites</p>
                <h3>A Laptop</h3>
            </div>
            <div className="project">
                <img src={ProjectIcon} alt="project-icon" />
                <p>Projects</p>
                <h3>3 Project</h3>
            </div>
            <div className="cost">
                <img src={CostIcon} alt="cost-bill-icon" />
                <p>Cost</p>
                <h3>N1,000,000 (Flexible Payment)</h3>
            </div>


        </div>
        <div className="what-to-know">
            <div className="about-course">
                <div className="about-heading">
                    <img src={AboutIcon} alt="about-icon" />
                    <h2>About the Course</h2>
                </div>
                <div className="about-text">
                    <p>Full-stack development involves crafting web applications that flawlessly combine both the client-side (frontend) and server-side (backend) elements. In this course, you'll explore the full spectrum of web development, gaining expertise in creating captivating user interfaces and building powerful backend systems.</p>
                </div>

            </div>
            <div className="what-to-learn">
                <div className="what-to-learn-heading">
                    <img src={LearnIcon} alt="learning-icon" />
                    <h2>Why Choose this Course</h2>
                </div>
                <div className="what-to-learn-text">
                    <ul>
                        <li><span>Comprehensive Curriculum: </span>This course is tailored to give you a comprehensive education in full-stack development, covering everything from the basics of HTML, CSS, and JavaScript to server-side scripting, database management, and server deployment.</li>
                        <li><span>Real-World Projects: </span>
                        You'll gain practical experience by working on real-world projects that simulate scenarios you'll face in the industry, ensuring you can apply what you learn effectively.</li>
                        <li><span>Experienced Instructors: </span>Our instructors, seasoned professionals with extensive experience in web development, will support and guide you through every stage of the learning journey.</li>
                        <li><span>Job-Ready Skills: </span>
                        Upon completing this course, you'll have a collection of projects showcasing your abilities and the knowledge required to begin a career in web development or start your own web-focused business.</li>
                        <li><span>Job-Ready Skills: </span>By the end of this course, you’ll have a portfolio of projects and the skills needed to pursue a career in web development or even launch your own web-based startup.</li>
                        <li>In-person training with immersive, hands-on practical sessions.</li>
                        <li>Web APIs and Asynchronous Applications</li>
                        <li>Engage in discussions and build connections with your classmates.</li>
                    </ul>
                </div>

            </div>
            <div className="prerequisites">
                <div className="prerequisites-heading">
                    <img src={RequiredIcon} alt="required-icon" />
                    <h2>Pre-requisites</h2>
                </div>
                <div className="prerequisites-text">
                    <p>For effective learning experiences, the following are required:</p>
                    <ul>
                        <li> Access to a computer – Mac or Windows.</li>
                        <li> Little or no previous coding experience.</li>
                        <li>Laptop Specifications(8gb -16gb RAM, 256SSD or 500gb)</li>
                        <li>Willingness to learn.</li>
                    </ul>
                </div>

            </div>
            <div className="course-outline">
                <div className="course-outline-heading">
                    <img src={CourseIon} alt="course-icon" />
                    <h2>Course Outline</h2>
                </div>
                <div className="course-outline-text">
                    <p>The following are our course outline:</p>
                    <h3>Frontend Web Development</h3>
                    <ul>
                        <li><span>Week 1: </span>Introduction to Web Development/HTML
                        <li>Task: Assignment</li>
                        </li>
                        <li><span>Week 2: </span>Styling HTML Web Pages 
                        <li>Task: Assignment</li>
                        </li>
                        <li><span>Week 3: </span>Intermediate CSS - Crafting responsive layouts
                        <li>Task: Capstone Project</li>
                        </li>
                        <li><span>Week 4: </span> CSS Frameworks, Using Tailwind CSS for responsive websites
                        <li>Task: Capstone Project</li>
                        </li>
                        <li><span>Week 5: </span>JavaScript Fundamentals and DOM(i)
                        <li>Task: Capstone Project</li>
                        </li>
                        <li><span>Week 6: </span>Javascript objects and Methods
                        <li>Task: Capstone Project</li>
                        </li>
                        <li><span>Week 7: </span>ES6 and JavaScript Modules
                        <li>Task: Capstone Project</li>
                        </li>
                        <li><span>Week 8: </span>Project Week 
                        <li>Task: Capstone Project</li>
                        </li>
                        <li><span>Week 9: </span>React – Getting Started
                        <li>Task: Capstone Project</li>
                        </li>
                        <li><span>Week 10: </span>React – (ii)-Single Page Applications (SPAs) with React
                        <li>Task: Capstone Project</li>
                        </li>
                        <li><span>Week 11: </span>CSS files in React
                        <li>Task: Capstone Project</li>
                        </li>
                        <li><span>Week 12: </span>React (iv)- Using the Switch Tag
                        <li>Task: Capstone Project</li>
                        </li>
                    </ul>
                    <h3>Backend Web Development</h3>
                    <ul>
                        <li><span>Week 1: </span>Introduction to Python Programming
                        <li>Task: Assignment</li>
                        </li>
                        <li><span>Week 2: </span>Data Structures & Looping Constructs
                        <li>Task: Assignment</li>
                        </li>
                        <li><span>Week 3: </span>Functions & Methods
                        <li>Task: Capstone Project</li>
                        </li>
                        <li><span>Week 4: </span>Object-Oriented Programming(OOP): Examination: Build a Python Project
                        <li>Task: Capstone Project</li>
                        </li>
                        <li><span>Week 5: </span>Introdction to Django
                        <li>Task: Capstone Project</li>
                        </li>
                        <li><span>Week 6: </span>Django Database Models class
                        <li>Task: Capstone Project</li>
                        </li>
                        <li><span>Week 7: </span>Django and REST APIs
                        <li>Task: Capstone Project</li>
                        </li>
                        <li><span>Week 8:</span> Front-End development tools
                        <li>Task: Capstone Project</li>
                        </li>
                        <li><span>Week 9: </span>Django Templates
                        <li>Task: Capstone Project</li>
                        </li>
                        <li><span>Week 10: </span>Deploying a Django Project
                        <li>Task: Capstone Project</li>
                        </li>
                        <li><span>Week 11: </span>DevOps
                        <li>Task: Capstone Project</li>
                        </li>
                        <li><span>Week 12: </span>Personal Project & Graduation
                        <li>Task: Capstone Project</li>
                        </li>
                    </ul>
                   
                </div>

            </div>
            <div className="class-schedule">
                <div className="class-schedule-heading">
                    <img src={ScheduleIcon} alt="schedule-icon" />
                    <h2>Class Schedule</h2>
                </div>
                <div className="class-schedule-text">
                    <p><span>Days of Class: </span> Mondays, Tuesdays, Wednesdays and Fridays</p>
                </div>
            </div>
        </div>
        <FullstackCallToAction/>
        <Expectation/>
        <Faq/>
        <FullstackCallToAction/>
        <Footer/>
       
       

    </div>
  )
}

export default FullStackPage