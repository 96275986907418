import React from 'react'
import '../Styles/calendar.css'
import CalendarData from '../Data/CalendarData.js'

function Calendar() {
  return (
    <div className='calendar-container'>
      <div className="calendar-text">
        <h2>Educational Timetable</h2>
        <p>Our 2025 Academic Schedule</p>
      </div>

      <div className="calender-card-holder">
      {CalendarData.map((item)=>(
        <div className="calendar-card">
          <h3>{item.heading}</h3>
          <p>Date: {item.date}</p>
          <p>Holiday: {item.holiday}</p>
        </div>
      ))}
      </div>
    
    </div>
  )
}

export default Calendar