import React from 'react'
import LandingPage from '../Components/LandingPage'
import WhatWeDo from '../Components/WhatWeDo'
import WhyUs from '../Components/WhyUs'
import Grow from '../Components/Grow'
import CallToAction from '../Components/CallToAction'
import Testimonial from '../Components/Testimonial'
import Faq from '../Components/Faq'
import Footer from '../Components/Footer'

function Home() {
  return (
    <div className='home-container'>
    <LandingPage/>
    <WhatWeDo/>
    <WhyUs/>
    <Grow/>
    <CallToAction/>
    <Testimonial/>
    <Faq/>
    <CallToAction/>
    <Footer/>
    </div>
  )
}

export default Home