import React from 'react'
import { Link } from 'react-router-dom'
import '../Styles/frontendCallToActionl.css'

function FrontendCallToAction() {
  return (
    <div className='frontend-call-to-action-container'>
        <h2>Frontend Web Development with React JS</h2>
        <p>Kickstart your journey as a Front-End Web Developer today. Master the skills to craft exceptional websites and interactive applications that deliver captivating user experiences.</p>
        <div className="frontend-buttons">
                <button className='enrol'> 
                    <Link to ='/FrontendApplyPage'> Join Us Now</Link>
                </button>
                {/* <button className='syllabus'> 
                    <Link to ='/FrontendSyllabusPage'> Download Syllabus</Link>
                </button> */}
            </div>
    </div>
  )
}

export default FrontendCallToAction