import React, { act, useState } from 'react'

import '../Styles/faq.css'
import FaqData from '../Data/FaqData'
import DownAngle from '../Assets/angle-down.svg'
import UpAngle from '../Assets/angle-up.svg'

function Faq() {

    const [showResponse, setShowResponse] = useState(true)
    const [activeContent, setActiveContent] = useState(1)


    const handleResponse = (index)=> {
        setShowResponse(!showResponse)

        setActiveContent(index)
    }

    console.log(showResponse, activeContent);
    
    
  return (
    <div className='faq-container'>
        <div className="faq-text">
            <h2>Frequently Asked Questions</h2>
        </div>

        {FaqData.map((item)=>(
            <div key={item.id} className="faq-questions-and-answers">
                <div onClick={()=> handleResponse(item.id)} className={showResponse && item.id === activeContent ? "questions active-question":"questions"}>
                    <p>{item.question}</p>
                   {showResponse && activeContent === item.id  ?<img onClick={handleResponse} src={UpAngle} alt="up-angle-icon" />:
                    <img onClick={handleResponse} src={DownAngle} alt="down-angle-icon" />}
                  
                </div>
                <div className="response">
                    {showResponse && item.id === activeContent && <p>{item.response}</p>}
                </div>
            </div>
        ))}
        
    </div>
  )
}

export default Faq