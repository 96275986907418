import TechSkill from '../Assets/tech-skill.jpg';
import RoadMap from '../Assets/roadmap.jpg';
import Environment from '../Assets/work-environment.jpg';
import Calendar from '../Assets/calender.jpg';
import NotAlone from '../Assets/not-alone.jpg';
import Partners from '../Assets/partners.jpg';



const GrowCardData = [
    {
        id: 1,
        heading: 'Acquire High-Demand Tech Skills',
        desc: 'Fast-track your tech career with our immersive training programs, designed to equip you with the most sought-after skills in today’s job market.',
        image: TechSkill
    },
    {
        id: 2,
        heading: 'Proven Learning Pathway',
        desc: 'Our courses are carefully crafted to guide you from the basics to mastery. At Pearlcity Hub, we provide you with the essential skills and industry practices needed to succeed in the modern workforce.',
        image: RoadMap
    },
    {
        id: 3,
        heading: 'Supportive Learning Environment',
        desc: 'At Pearlcity Hub, we ensure a comfortable and well-equipped space for your learning, providing all the essential amenities to enhance your educational experience.',
        image: Environment
    },
    {
        id: 4,
        heading: 'Flexible Scheduling Options',
        desc: 'We offer weekday and weekend classes, available both in-person and online, with new cohorts starting every quarter. Whether you have a full-time job or a flexible schedule, you can easily fit learning into your life.',
        image: Calendar
    },
    {
        id: 5,
        heading: 'A Supportive Community',
        desc: 'Join a network of like-minded students where collaboration and growth are encouraged. Learn from experienced facilitators who share valuable insights and best practices, ensuring you\'re never alone on your learning journey.',
        image: NotAlone
    },
    {
        id: 6,
        heading: 'Trusted Hiring Partners',
        desc: 'We connect you with top-tier tech talent, ready to meet your team’s needs. Our professionals are fully equipped with the latest skills and industry knowledge to deliver cutting-edge tech solutions.',
        image: Partners
    },
]

export default GrowCardData;