import React from 'react'
import '../Styles/expectation.css'
import ExpectationData from '../Data/ExpectationData'

function Expectation() {
  return (
    <div className='expectation-container'>
        <div className="expectation-text">
            <h2>Bring your expectations with you.</h2>
        </div>
        <div className="expectation-card-holder">
        {ExpectationData.map((item)=>( 
            <div key={item.id} className="expectation-card">
                    <img src={item.icon} alt="icons" />
                    <h3>{item.heading}</h3>
                    <p>{item.desc}</p>
            </div>
        ))}
        </div>
       
    </div>
  )
}

export default Expectation